;
var AFL = window.AFL || {};
AFL.settings = AFL.settings || {};

AFL.settings = {
  showABTest:false,
};
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  AFL.setCookie = function(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

// utility function called by getCookie( )
AFL.getCookieVal = function(offset) {
  var endstr = document.cookie.indexOf (";", offset);
  if (endstr == -1) {
    endstr = document.cookie.length;
  }
  return unescape(document.cookie.substring(offset, endstr));
}

// primary function to retrieve cookie by name
AFL.getCookie = function(name) {
  var arg = name + "=";
  var alen = arg.length;
  var clen = document.cookie.length;
  var i = 0;
  while (i < clen) {
    var j = i + alen;
    if (document.cookie.substring(i, j) == arg) {
      return AFL.getCookieVal(j);
    }
    i = document.cookie.indexOf(" ", i) + 1;
    if (i == 0) break;
  }
  return "";
}


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {

      action_load_posts : 'ajax_load_posts',
      action_load_subject_posts : 'ajax_load_subject_posts',
      action_load_author_posts : 'ajax_load_author_posts',
      action_load_form : 'ajax_load_form',
      action_load_printables : 'ajax_load_printables',
      action_location : 'ajax_location',

      init: function() {
        AFL.settings.pageID = $("body").attr("data-page-id");


        //A/B testing
          var options = ['a', 'b', 'c', 'd', 'e', 'f'];
          var randomLetter = options[Math.floor(Math.random()*options.length)];

        //console.log('randomLetter',randomLetter,AFL.getCookie('abtest'));
        if (AFL.getCookie('abtest2') === ""){


          AFL.setCookie('abtest2',randomLetter,30);

        }

        //console.log('cookie set',AFL.getCookie('abtest'));

        var abHtml = $("#ab-post").html();

        if ($(".main").data('ab') === 'enabled' && $.trim(abHtml) !== ''){

          //used to hide newsletter popup
          AFL.settings.showABTest = true;




          if (AFL.getCookie('abtest2') === 'a' || AFL.getCookie('abtest2') === 'e'){
            var abtestcalled = false;
            
            if(AFL.getCookie('abtest2') === 'e'){
            //popup with two ctas
              abHtml = $(abHtml).find(".ab-article-test-activities").attr("style","display:flex").end()[0].outerHTML;
              abHtml = $(abHtml).find(".single-test").attr("style","display:none").end()[0].outerHTML;
            }


            $(window).scroll(function(){

              var entryContentHeight = $(".entry-content").height();

              if ($(window).scrollTop() >= (entryContentHeight * 0.45)){
                if (abtestcalled === false){

                  setTimeout(function(){

                    $.featherlight(abHtml,{
                      persist:'false',
                      type:'html'
                    });

                  },200);
                  abtestcalled = true;
                }
              }

            });


          }else if(AFL.getCookie('abtest2') === 'b'){
            // cookie B is control, does nothing
          }else if(AFL.getCookie('abtest2') === 'c'){
            //sidebar sticky
            $(".sidebar").empty().append(abHtml).addClass("sidebar--sticky");
          
          }else if(AFL.getCookie('abtest2') === 'd'){
            //after paragraph 2
            $(".entry-content").find("p").eq(1).append(abHtml);
          
          
          }else if(AFL.getCookie('abtest2') === 'f'){
            //after paragraph 2
            $(".entry-content").append(abHtml);
          
          }

            // $(".sidebar").html(abHtml);
        }



        function buildFilterIds(dataAttribute,filterType){
          var filter_ids;
          $('.post-filters .'+filterType+' .filter.active').each(function(index){

            if (index > 0){
              filter_ids = filter_ids + ',';
            }
            if (index == 0){
              filter_ids = $(this).attr(dataAttribute);
            }else{
              filter_ids = filter_ids + $(this).attr(dataAttribute);
            }

          })
          return filter_ids;
        }


        /*
        if (AFL.getCookie('survey') != "1"){

          $("body").append('<div class="cookie-overlay"><div class="container"><div class="close-button">X</div><p>Want a chance to win a $100 Amazon gift card?<br />Help Active for Life by taking our 30 second survey. </p><a class="btn-accept resources-button" href="https://www.surveymonkey.com/r/AfLSummer22" target="_blank">Take Survey</a></div></div>');


          setTimeout(function(){
            $(".cookie-overlay").addClass('fadein');
          },500)

          $(".cookie-overlay .close-button").click(function(e){

            e.preventDefault();
            AFL.setCookie('survey','1',30);
            $(".cookie-overlay").remove();

          })

        }
        */


          if (AFL.getCookie('acceptcookies') != "1"){


            window.dataLayer = window.dataLayer || [];
            function gtag(){
              dataLayer.push(arguments);
            }
            

            var data = {
              action: Sage.common.action_location
            };

            $.get(afl.ajaxurl, data, function (requiresConsent) {


              if (requiresConsent === '1'){

                $("body").append('<div class="cookie-overlay"><div class="container"><p>Active for Life uses cookies to improve the user experience of our website. Continuing to use our website means you agree to all cookies as outlined in our <a href="/privacy-policy/">Privacy Policy</a> and <a href="/terms-of-service-active-for-lifekidactive/">Terms of Service</a> notice.</p><a class="btn-accept resources-button">Continue</a></div></div>');


                setTimeout(function(){
                  $(".cookie-overlay").addClass('fadein');
                },500)

                $(".btn-accept").click(function(e){

                  e.preventDefault();

                  //updates all permissions
                  gtag('consent', 'update', {
                    'ad_storage': 'granted',
                    'ad_user_data': 'granted',
                    'ad_personalization': 'granted',
                    'analytics_storage': 'granted'
                  });

                  AFL.setCookie('acceptcookies','1',30);
                  $(".cookie-overlay").remove();

                })

            }else{

              //set cookie to stop ajax calls on non-UK
              AFL.setCookie('acceptcookies','1',30);

            }

          });

        }


        $(".post-filters button").click(function(){
          $(this).parent().toggleClass("open");
        })

        // Add click event for load more btn
        $('.load-more-btn').click(function(){
          if( Sage.common.loading ){ return; }

          if ($(this).hasClass("load-more-btn--author")){
            var authorId = $(this).data("author-id");
            var offset = $('.post-block-wrap').length;
            Sage.common.load_author_posts( authorId, offset );//loads them all


          }else if($(".load-more-btn").attr("data-term-id")){
            //for 'content-subjects' pages

            var tax_name = $(this).data("tax-id");
            var tax_term = $(this).data("term-id");
            var layout_type = $(this).data("layout-type");

            // get current offset
            var offset = $('.selected-tax').length;
            if (offset === 0){
              offset = $('.post-block-wrap').length;
            }
            //console.log(tax_name,tax_term,offset,layout_type);
            Sage.common.load_subject_posts(tax_name, tax_term, offset, layout_type);

          }else{
            // get current filter
            var topics_filter = buildFilterIds('data-term-id','topics');
            var audience_filter = buildFilterIds('data-audience-id','audience');
            var layout_type = $(this).data("layout-type");
            // get current offset
            var offset = $(this).data("offset") ? $(this).data("offset") : $('.post-block-wrap').length;
            if (layout_type === 'block'){
              offset = $('.article-block__article').length;
            }
            //console.log(topics_filter,audience_filter,offset);
            Sage.common.load_posts(topics_filter, offset, audience_filter, layout_type);

          }

        });



/*
take the pop up we see when you click newsletter in the nav and have it pop up like that when someone
scrolls 25% on blog. We would also need to cookie it to make sure when they x out that they are not asked
again. We would also like tracking on it to see how many sign ups came from this popup. (could use the
confirmed sign up page.
*/

        //var $formHtml = $("#subscribe-footer-form");
        //var $formHtml2 = $("#subscribe-footer-form");
    /*
  Newsletter Footer FR, Newsletter Footer EN,
  Newsletter Sidebar FR, Newsletter Sidebar EN,
  Newsletter Popup FR, Newsletter Popup EN,
    */
        var popupFormID = 'Newsletter Popup EN';
        var clickPopupFormID = 'Newsletter Popup EN';

        //if (wpml_cookies['wp-wpml_current_language'].value == 'fr'){
        if ($('body').data('language') == 'fr'){
          popupFormID = 'Newsletter Popup FR';
          clickPopupFormID = 'Newsletter Popup FR';
        }

        //button only on mobile now
        $("#header-subscribe-button").click(function(e){

          e.preventDefault();


          var data = {
            action: Sage.common.action_load_form,
            formID: clickPopupFormID
          };

          $.get(afl.ajaxurl, data, function(html) {

            $.featherlight(html,{
              persist:'false',
              type:'html'
            })

          });
        /*
        $.featherlight($formHtml2,{
            persist:'false',
            type:'html'
          })
          */

          /*for iframe, can't send to confirmation page*/
          /*
          $.featherlight('/wp/wp-admin/admin-ajax.php?action=ajax_load_form&formID=4',{
            persist:'false',
            type:'iframe'
          })
          */


        })

        if ($(".newsletter-sign-up").data('form-id') === 'Active Start'){
          popupFormID = 'Active Start Popup'; 
        }

        var called = false;
        if (AFL.getCookie('subscribeview') != 1){

          if ($("body").hasClass("single-post") && !AFL.settings.showABTest){

            $(window).scroll(function(){
              //if ($(window).scrollTop() >= ($(document).height() * 0.75) - $(window).height()){
              if ($(window).scrollTop() >= ($(window).height() * 0.25)){
                if (called === false){
                  /*
                  current({
                    beforeOpen: function(){
                      alert('hello');
                      return false; // prevent lightbox from opening
                    }
                  });
                  */


                    setTimeout(function(){

                      var data = {
                        action: Sage.common.action_load_form,
                        formID: popupFormID
                      };

                      $.get(afl.ajaxurl, data, function(html) {

                        //html.find('.scroll-subscriber input').attr("value",'true');

                        $.featherlight(html,{
                          persist:'false',
                          type:'html'
                        })

                      });


                    },200);

                  AFL.setCookie('subscribeview',1, 90);
                  called = true;
                }
              }
            });


          }

        }





        // Add click event to blog filters
        $('.post-filters .filter').click(function(e){
          e.preventDefault();
          if( Sage.common.loading ){ return; }

          //var text = $(this).find('span.filter-text-wrap').text();
          //$('button.dropdown-toggle').text(text);





          //articles page does ajax call


          if ($(this).parent().hasClass('resources')){
            if ($(this).hasClass('active')){
               $(this).removeClass('active');
            }else{
              $(this).parent().find('.filter').removeClass('active');
              $(this).addClass('active');
            }

          }else{

            if ($(this).hasClass('active')){
               $(this).removeClass('active');
            }else{
              $(this).addClass('active');
            }

            var topics_filter = buildFilterIds('data-term-id','topics');
            var audience_filter = buildFilterIds('data-audience-id','audience');


            Sage.common.load_posts(topics_filter, 0, audience_filter);



          }




        });

        $("#module-wrap").on("click", ".filter-link", function(e){
          //clicks on filter links are not audience types
          if ($('.post-filters').length > 0){//same links do nothing if there are no filters on that page
            e.preventDefault();
            //for multiple filter listings like on /articles (index.php)
            var dataTermId = $(this).attr('data-term-id');
            if (dataTermId){
              $('.post-filters .filter[data-term-id="'+dataTermId+'"]').trigger("click");
            }
            //for parent/professionals filters
            var dataTermName = $(this).attr('data-term-name');
            if (dataTermName){
              $('.post-filters .filter[data-filter="'+dataTermName+'"]').trigger("click");
            }
          }
        });

        //single posts
        $(".previous-post").click(function(){
          window.history.back();
        })


        //google events for pdf links
        //ga('send', 'event', 'category', 'action', 'label')
        /*
        $(".single.lesson-plan-units .entry-content a,.single.individual-lesson-plans .entry-content a").click(function(e){
          var actionName = $(this).text() + " Click";
          var labelName = $(this).attr("href");
          //console.log(actionName,labelName);
          ga('send', 'event', 'Click Lesson Plan Downloads', actionName, labelName);
          //if (this.labelName) {
            e.preventDefault();
            setTimeout(function() {location.href = labelName}, 150);
          //}

        })
        */


        $(".most-popular a").click(function(e){
          var actionName = $(this).attr("title") + " Click";
          var labelName = $(this).attr("href");
          console.log(actionName,labelName);
          ga('send', 'event', 'Click Most Popular', actionName, labelName);
          //if (this.labelName) {
            e.preventDefault();
            setTimeout(function() {location.href = labelName}, 150);
          //}
        })


        $('.printable-carousel--mobile-only').slick({
          lazyLoad: 'ondemand',
          autoplaySpeed: 8000,
          autoplay: false,
          mobileFirst: true,
          responsive: [{
            breakpoint: 1080,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }]
        })

        $('.printable-carousel--all').slick({
          lazyLoad: 'ondemand',
          autoplaySpeed: 8000,
          autoplay: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        })

        //for full printables panel
        $('.printables-section').slick({
          //lazyLoad: 'ondemand',
          autoplaySpeed: 8000,
          autoplay: false,
          //mobileFirst: true,
          slidesToShow: 4,
          slidesToScroll: 1,
          responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
          ]
        })

        //related articles
        $("#featured-articles-nav a").click(function(e){
          var actionName = $(this).text() + " Click";
          var labelName = $(this).attr("href");
          console.log(actionName,labelName);
          ga('send', 'event', 'Click Related', actionName, labelName);
          //if (this.labelName) {
            e.preventDefault();
            setTimeout(function() {location.href = labelName}, 150);
          //}
        })


        //homepage
        $('#featured-articles').slick({
          //lazyLoad: 'ondemand',  needs img tag to be <img data-lazy="img/lazyfonz1.png"/>
          //autoplay: true,
          //autoplaySpeed: 5000,

        }).on('beforeChange',function(event,slick,currentSlide,nextSlide){
             $("#featured-articles-nav li").eq(nextSlide).addClass("active").parent().find("li").not(':eq('+nextSlide+')').removeClass("active")
          })
        $("#featured-articles-nav li a").mouseover(function(){
            var index = $(this).parent().index();
            $('#featured-articles').slick("slickGoTo", index);

            $(this).parent().addClass("active").parent().find("li").not(':eq('+index+')').removeClass("active");

            return false;
        }).eq(0).parent().addClass("active");


        $(".back-home .icon-close").click(function(){
          $(this).parent().hide();
          AFL.setCookie('ch',afl.post_id, -1);
        })

        //printables component
        $('.printables-section_language .selectpicker').change(function() {
          var languageID = $(this).val();
          var printablesID = $(this).data("id");
          var $currentPrintable = $(this).parent().parent();
          Sage.common.load_printables(printablesID,languageID,$currentPrintable);
        });


      },
      finalize: function() {
        //fire after init on all pages

            /*
          Newsletter Footer FR, Newsletter Footer EN,
          Newsletter Sidebar FR, Newsletter Sidebar EN,
          Newsletter Popup FR, Newsletter Popup EN,
            */
        var formID = $(".sidebar .newsletter-sign-up").data("form-id");



        var sidebarFormID;

        //if (formID){//not set for home page
        if (formID){
          sidebarFormID = formID;

        }else{

          sidebarFormID = 'Newsletter Sidebar EN';

          //if (wpml_cookies['wp-wpml_current_language'].value == 'fr'){
          if ($('body').data('language') == 'fr'){
            sidebarFormID = 'Newsletter Sidebar FR';
          }

        }


        var footerFormID = 'Newsletter Footer EN';

        //if (wpml_cookies['wp-wpml_current_language'].value == 'fr'){
        if ($('body').data('language') == 'fr'){
          footerFormID = 'Newsletter Footer FR';
        }


        //no ajax call just for ACTIVE START
        if (sidebarFormID !== 'Active Start'){
          var data = {
            action: Sage.common.action_load_form,
            formID: sidebarFormID
          };

          $.get(afl.ajaxurl, data, function(html) {

            $(".home-newsletter-subscribe, .sidebar .newsletter-sign-up").prepend(html);

          });

        }

        var data = {
          action: Sage.common.action_load_form,
          formID: footerFormID
        };

        $.get(afl.ajaxurl, data, function(html) {

          $(".footer .newsletter-sign-up").prepend(html);

        });


      },


      load_posts: function( pTopicsFilter, pOffset, pAudienceFilter, pLayoutType ) {
        Sage.common.loading = true;

        var scrollToAfterLoad = $(document).scrollTop();//$(".load-more-btn").offset().top - 150;
//console.log(scrollToAfterLoad);
        if(pOffset === 0){
          // only clear current contents if the offset is 0 because otherwise we are loading more
          $('.loader').removeClass('all-posts-loaded');

          $('#module-wrap').empty();
        }

        $('.loader').addClass('loading');
        var data = {
          action: Sage.common.action_load_posts,
          topics_filter: pTopicsFilter,
          post_offset: pOffset,
          audience_filter: pAudienceFilter,
          layout_type: pLayoutType,
        };

        $.get(afl.ajaxurl, data, function(html) {

          if($(html).filter(".entry-content").length > 0) {
            //.entry-content is only returned where no articles available message comes back
            $('.loader').addClass('all-posts-loaded');
          }

          if (pLayoutType === 'block'){
            $('#ajax-article-block').append(html);
          }else{
            $('#module-wrap').append(html);
          }



          $('.loader').removeClass('loading');
          Sage.common.loading = false;

          //console.log("scrollToAfterLoad",scrollToAfterLoad);

          //console.log(data.post_offset);
          if(data.post_offset > 0){
            $("html, body").animate({"scrollTop": scrollToAfterLoad});
          }


        });

      },
      

      load_author_posts: function( pAuthorId, pOffset ) {
        Sage.common.loading = true;

        var scrollToAfterLoad = $(".load-more-btn").offset().top - 150;


        $('.loader').addClass('loading');
        var data = {
          action: Sage.common.action_load_author_posts,
          author_id: pAuthorId,
          post_offset: pOffset
        };

        $.get(afl.ajaxurl, data, function(html) {

          if($(html).filter(".entry-content").length > 0) {
            //.entry-content is only returned where no articles available message comes back
            $('.loader').addClass('all-posts-loaded');
            $(".load-more-btn").addClass("hidden");
          }

          $('#module-wrap').append(html);

          $('.loader').removeClass('loading');
          Sage.common.loading = false;

          //console.log("scrollToAfterLoad",scrollToAfterLoad);

          $("html, body").animate({"scrollTop": scrollToAfterLoad});

          //$(".load-more-btn").addClass("hidden");
          

        });
      },
      load_subject_posts: function( pTaxCategory, pTaxTerm, pOffset, pLayoutType ) {
        Sage.common.loading = true;

        var scrollToAfterLoad = $(".load-more-btn").offset().top - 150;


        $('.loader').addClass('loading');
        var data = {
          action: Sage.common.action_load_subject_posts,
          cat_filter: pTaxCategory,
          term_filter: pTaxTerm,
          post_offset: pOffset,
          layout_type: pLayoutType
        };

        $.get(afl.ajaxurl, data, function(html) {

          if($(html).filter(".entry-content").length > 0) {
            //.entry-content is only returned where no articles available message comes back
            $('.loader').addClass('all-posts-loaded');
          }

          $('#module-wrap').append(html);


          $('.loader').removeClass('loading');
          Sage.common.loading = false;

          //console.log("scrollToAfterLoad",scrollToAfterLoad);

          $("html, body").animate({"scrollTop": scrollToAfterLoad});


        });

      },

      load_printables: function( pPrintablesID, pLanguage, $currentPrintable ) {
        
        $currentPrintable.find(".printables-section_switcher").addClass('loading');

        var data = {
          action: Sage.common.action_load_printables,
          id: pPrintablesID,
          language: pLanguage
        };

        $.get(afl.ajaxurl, data, function(html) {

          //console.log(html);

          $currentPrintable.find(".printables-section_switcher").empty().append(html);

          $currentPrintable.find(".printables-section_switcher").removeClass('loading');

        });

      }


    },
    // Home page
    'home': {
      init: function() {

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'articles': {
      init: function() {
        // external js: isotope.pkgd.js


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS 
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

jQuery(function($) {
    if ($(window).width() < 1081) {
      $(".dropdown-toggle").attr('data-toggle', 'dropdown');
      $(".dropdown-toggle").removeAttr('data-hover', 'dropdown');
    } else {
      $(".dropdown-toggle").removeAttr('data-toggle dropdown');
    }
  // Bootstrap menu magic
  $(window).resize(function() {
    if ($(window).width() < 1081) {
      $(".dropdown-toggle").attr('data-toggle', 'dropdown');
      $(".dropdown-toggle").removeAttr('data-hover', 'dropdown');
    } else {
      $(".dropdown-toggle").removeAttr('data-toggle dropdown');
    }

    //sizing to large from small might need this
    //$('.printables-section').slick('slickGoTo', 0);

  });


  $("body").on("click",".event-button",function(){
    $(".kid-field.show").last().next().addClass("show");
  });



});


